
					@import './src/sass/variables';
					@import './src/sass/mixins';
				































































.input-group {
	flex-flow: row wrap;
}

.input-group-row {
	margin-top: 10px;
	margin-right: $default_padding;
}

.block-inner {
	display: flex;
	flex-flow: row wrap;
	align-items: center;

	header {
		flex: 1;
		margin-bottom: 0;
		font-weight: inherit !important;
	}

	.options {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-evenly;
	}

}

@include breakpoint('small') {
	.block-inner {
		display: block;

		header {
			margin-bottom: $default_padding;
		}
	}
}

.option-true,
.option-false {
	position: relative;
	padding: 0 $default_padding * 2;
	cursor: pointer;

	p {
		font-size: 0.8em;
		text-transform: uppercase;
		text-align: center;
		margin-top: 5px;
		margin-bottom: 0;
	}

	input[type='radio'] {
		position: absolute;
		visibility: hidden;
	}
}

.option-true::v-deep {
	polyline,
	path {
		fill: rgba($color: $color__green, $alpha: 0);
		transition: fill 0.2s ease;
	}

	&:hover {
		polyline,
		path {
			fill: rgba($color: $color__green, $alpha: 0.4);
		}
	}

	input:checked {
		& ~ p {
			font-weight: bold;
		}

		& + svg {
			polyline,
			path {
				fill: rgba($color: $color__green, $alpha: 0.8);
			}
		}
	}
}

.option-false::v-deep {
	polyline,
	path {
		fill: rgba($color: $color__red, $alpha: 0);
		transition: fill 0.2s ease;
	}

	&:hover {
		polyline,
		path {
			fill: rgba($color: $color__red, $alpha: 0.4);
		}
	}

	input:checked + svg {
		& ~ p {
			font-weight: bold;
		}

		polyline,
		path {
			fill: rgba($color: $color__red, $alpha: 0.8);
		}
	}
}

